import React from "react";
import { useState, useEffect } from "react";
import "./GroupDetails.css";
import GReadonlyRow from "./GReadonlyRow";
import GeditableRow from "./GeditableRow";
import moment from "moment";
import Loader from "../Loader/Loader";

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function GroupDetails({ token }) {
  const [groupDetail, setGroupDetail] = useState("");
  const [startDate, setStartDate] = useState("");
  const [savedData, setSavedData] = useState([]);
  const [editGroupId, setEditGroupId] = useState(null);
  const [editGroupData, setEditGroupData] = useState("");
  const [alert, setAlert] = useState(false);
  const [dateAlert, setDateAlert] = useState(false);
  const [noDataAlert, setNoDataAlert] = useState(false);
  const [invalidDateAlert, setInvalidDateAlert] = useState(false);
  const [loading, setLoading] = useState(true);

  //Pagination
  const pages = Math.ceil(savedData.length / 30);
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = [];

  function goToNextPage() {
    if (currentPage < pages) {
      setCurrentPage((page) => page + 1);
    }
  }

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage((page) => page - 1);
    }
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * 30 - 30;
    const endIndex = startIndex + 30;
    return savedData.slice(startIndex, endIndex);
  };

  for (let i = 1; i <= pages; i++) {
    pageCount.push(i);
  }

  //Apicall on pageload
  console.log("BaseURL : ",baseUrl)
  const getData = () => {
    console.log("environment :  ", process.env)
    fetch(`${baseUrl}/FetchGroupDataAPI`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((r) => r.json())
      .then((res) => {
        if (res) {
          setSavedData(res.records);
          setLoading(false);
        } else {
          console.log("else new post resp", res);
        }
      });
  };

  //Reset button Pressed
  const resetHandler = () => {
    setGroupDetail("");
    setStartDate("");
    setAlert(false);
    setDateAlert(false);
    setNoDataAlert(false);
    setInvalidDateAlert(false);
  };

  const validateDate = (testDate) => {
    return moment(testDate, "YYYY-MM-DD", true).isValid() ? false : true;
  };
  const addData = () => {
    setLoading(true);
    fetch(`${baseUrl}/AddGroupDataAPI`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        diV_GRP_TEXT: groupDetail,
        diV_GRP_STRT_DT: startDate,
      }),
    })
      .then((r) => r.json())
      .then((res) => {
        if (res) {
          console.log("new post resp", res);
          setLoading(false);
        } else {
          console.log("else new post resp", res);
        }
      });
    const newData = [...savedData];
    const updatedData = [...savedData, newData];
    setSavedData(updatedData);
    setGroupDetail("");
    setStartDate("");
    setAlert(false);
    setDateAlert(false);
  };
  //add Button Pressed
  const addHandler = () => {
    if (startDate !== "" && groupDetail !== "") {
      let dateChecker = validateDate(startDate);
      setInvalidDateAlert(dateChecker);
      let dataCheck = false;
      if (dateChecker === false) {
        for (let i = 0; i < savedData.length; i++) {
          if (
            savedData[i].diV_GRP_TXT.toLowerCase().trim() ===
            groupDetail.toLowerCase().trim()
          ) {
            dataCheck = true;
            break;
          }
        }
      }
      setNoDataAlert(dataCheck);
      if (dateChecker === false && dataCheck === false) {
        addData();
      }
    } else if (startDate === "" && groupDetail !== "") {
      setAlert(false);
      setDateAlert(true);
    } else if (startDate !== "" && groupDetail === "") {
      setDateAlert(false);
      setAlert(true);
    } else {
      setAlert(true);
      setDateAlert(true);
    }
  };

  //Edit buttonpressed
  const editHandler = (event, item) => {
    event.preventDefault();
    setEditGroupId(item.diV_GRP_CD);
    setEditGroupData(item.diV_GRP_TXT);
  };

  //When text gets edited
  const handleEditGroupDetails = (event) => {
    event.preventDefault();
    setEditGroupData(event.target.value);
  };

  //Save button Pressed
  const handleSave = (e, item) => {
    setLoading(true);
    e.preventDefault();
    fetch(`${baseUrl}/EditGroupDataAPI`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        diV_GRP_CD: item.diV_GRP_CD,
        diV_GRP_TXT: editGroupData,
      }),
    }).then((res) => {
      if (res) {
        console.log("new post resp", res);
        setLoading(false);
      } else {
        console.log("else new post resp", res);
      }
    });
    const newData = [...savedData];
    const index = savedData.findIndex(
      (data) => data.diV_GRP_CD === item.diV_GRP_CD
    );
    newData[index].diV_GRP_TXT = editGroupData;

    setSavedData(savedData);

    getData();
    setEditGroupId(null);
  };

  //Cancel Button Pressed
  const handleCancel = () => {
    setEditGroupId(null);
  };

  //Deactivation button Clicked
  const handleDeactivation = (e, item) => {
    e.preventDefault();
    fetch(`${baseUrl}/DeleteGroupDataAPI`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        diV_GRP_CD: item.diV_GRP_CD,
        diV_GRP_TXT: item.diV_GRP_TXT,
      }),
    })
      .then((r) => r.json())
      .then((res) => {
        if (res) {
          console.log("new post resp", res);
        } else {
          console.log("else new post resp", res);
        }
      });
    setEditGroupId(null);
  };

  useEffect(() => {
    getData();
  }, [savedData]);

  return (
    <div className="container">
      <div className="headerText">Group Maintenance</div>

      <div class="row">
        <div class="col-xs-6 col-md-2" style={{ textAlign: "right" }}>
          Group Description:
        </div>
        <div class="col-xs-4 col-md-3">
          <input
            style={{ width: "200px", height: "30px" }}
            type="text"
            onChange={(e) => setGroupDetail(e.target.value)}
            value={groupDetail}
            required="required"
          />
        </div>
        <div class="col-xs-6 col-md-2" style={{ height: "30px" }}>
          {alert ? <div className="alert">*Required</div> : ""}
          {noDataAlert ? <div className="alert">*Duplicate Records</div> : ""}
        </div>
      </div>
      <div class="row" style={{ marginTop: "1%" }}>
        <div class="col-xs-6 col-md-2" style={{ textAlign: "right" }}>
          Start Date:
        </div>
        <div class="col-xs-4 col-md-3">
          <input
            style={{ width: "200px", height: "30px" }}
            type="date"
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
            required="required"
          />
        </div>
        <div class="col-xs-6 col-md-2" style={{ height: "30px" }}>
          {dateAlert ? <div className="alert">*Required</div> : ""}
          {invalidDateAlert ? <div className="alert">*Invalid Date</div> : ""}
        </div>
      </div>
      <div className="buttonHolder">
        <button className="button" onClick={addHandler}>
          Add
        </button>
        <button className="button" onClick={resetHandler}>
          Reset
        </button>
      </div>
      <>
        {loading ? (
          <Loader />
        ) : (
          <table>
            <thead>
              <tr>
                <th>Action</th>
                <th>Group Description</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              {getPaginatedData().map((item, index) => (
                <React.Fragment key={index}>
                  {editGroupId === item.diV_GRP_CD ? (
                    <GeditableRow
                      item={item}
                      handleEditGroupDetails={handleEditGroupDetails}
                      handleCancel={handleCancel}
                      handleSave={handleSave}
                      handleDeactivation={handleDeactivation}
                      editGroupData={editGroupData}
                    />
                  ) : (
                    <GReadonlyRow item={item} editHandler={editHandler} />
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </>
      <div className="paginationWrapper">
        <div>
          Showing {(currentPage - 1) * 30 + 1} to{" "}
          {savedData.length > (currentPage - 1) * 30 + 30
            ? (currentPage - 1) * 30 + 30
            : savedData.length}{" "}
          of {savedData.length} entries
        </div>
        <div>
          {pages > 1 && savedData.length !== 0 ? (
            <ul class="pagination">
              <li
                className={` ${currentPage === 1 ? "disabled" : ""}`}
                onClick={goToPreviousPage}
              >
                <a href="#!">
                  <i class="fa fa-angle-left"></i>
                </a>
              </li>

              {pageCount.map((item, index) => (
                <li
                  key={index}
                  onClick={changePage}
                  className={` ${currentPage === item ? "active" : null}`}
                >
                  <a href="#!">
                    {item} <span class="sr-only"></span>
                  </a>
                </li>
              ))}
              <li
                onClick={goToNextPage}
                className={`${currentPage === pages ? "disabled" : ""}`}
              >
                <a href="#!">
                  <i class="fa fa-angle-right"></i>
                </a>
              </li>
            </ul>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
